import { client } from '../client';
import Endpoints from '../Endpoints';
import { getData } from '../apiUtils';

type Vars = {
  profilePicture?: string;
  userId: string;
};

export default async function updateUserAvatar({ profilePicture, userId }: Vars) {
  return client
    .put(`${Endpoints.profilePictureUrl}/${userId}`, {
      profilePicture,
    })
    .then(getData);
};
