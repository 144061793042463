import * as R from 'ramda';

export const hasAnyAuthority = (privileges: string[], hasAnyPrivileges: string[]) => {
    if (privileges && privileges.length !== 0) {
      if (hasAnyPrivileges?.length === 0) {
        return true;
      }
      return hasAnyPrivileges && R.not(R.isEmpty(R.intersection(hasAnyPrivileges, privileges)));
    }
    return false;
  };