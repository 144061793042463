import { createContext, useEffect, useReducer, useState } from "react";
import type { FC, ReactNode } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/azureConfig";
import { User } from "../types/user";
import getUserProfile from "../api/requests/getUserProfile";
import { APICodes, federations, storageKeys } from "../Static/constants";
import { useMediaQuery } from '@mui/material';
import KeycloakServices from "../KeycloakConfig";
import Keycloak from 'keycloak-js';
// const keycloak: any = new Keycloak('/keycloak.json');

interface State {
  accessToken: any;
  user: User | null;
  privileges: string[];
  loading: boolean;
}

export interface AuthContextValue extends State {
  headerTitle: {title: string, backIcon: boolean, route: any};
  headerHandler: (title: any, backIcon: boolean, route: any) => void;
  errorMsg: string;
}

interface AuthProviderProps {
  children?: ReactNode;
}

const initialState: State = {
  accessToken: null,
  user: null,
  privileges: [],
  loading: true,
};

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    accessToken: string;
    user: User | null;
    privileges: string[];
    loading: boolean;
  };
};

type Action = InitializeAction;

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState, headerTitle: {title: '', backIcon: false, route: ''}, headerHandler: (title: any, backIcon: boolean, route: any) => {}, errorMsg: '',
});

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { accessToken, user, privileges, loading } = action.payload;

    return {
      ...state,
      accessToken,
      user,
      privileges,
      loading
    };
  },
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  // const { instance, accounts } = useMsal();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [headerTitle, setHeaderTitle] = useState<any>({title: '', backIcon: false, route: ''});
  const [errorMsg, setErrorMsg] = useState<string>("")
  const isMobile = useMediaQuery('(max-width: 751px)');

  const initialize = async (): Promise<void> => {
    try {
      setLoading(true);
      // if (localStorage.getItem('SignInWith') === federations.AZURE) {
      //   const response = await instance.acquireTokenSilent({
      //     ...loginRequest,
      //     account: accounts[0]
      //   });
      //   localStorage.setItem(storageKeys.AuthToken, response.accessToken);
      // }
      const userDetails = localStorage.getItem(storageKeys.AuthToken) && await getUserProfile();
      const user: any = userDetails?.data?.user;

      const privileges: any = userDetails?.data?.privileges?.map((item: any) => item?.privilege?.name);
      // if (localStorage.getItem('SignInWith') === federations.AZURE) {
      //   user === null && instance.logoutRedirect({
      //     postLogoutRedirectUri: "/"
      //   });
      //   user === null && localStorage.removeItem(storageKeys.AuthToken);
      // }

      // if (localStorage.getItem('SignInWith') === federations.KEYCLOAK) {
        // if (user === null) window.location.href = "/error";
      // }

      dispatch({
          type: 'INITIALIZE',
          payload: {
              accessToken: localStorage.getItem(storageKeys.AuthToken) ?? "",
              user,
              privileges,
              loading
          }
      });
      setLoading(false)
    } catch (err: any) {
      if(err?.response?.data?.statusCode === APICodes.FAILURE || err?.errorCode === 'login_required') {
        setErrorMsg(err?.response?.data?.message)
        
        // localStorage.removeItem(storageKeys.AuthToken);
        // if (localStorage.getItem(storageKeys.SignInWith) === federations.AZURE) {
        //   instance.logoutRedirect({
        //     postLogoutRedirectUri: "/"
        //   });
        //   localStorage.removeItem(storageKeys.SignInWith);
        // }
        // if (localStorage.getItem(storageKeys.SignInWith) === federations.KEYCLOAK) {
        //   KeycloakServices.logout();
        //   localStorage.removeItem(storageKeys.SignInWith);
        // }
      }
      dispatch({
        type: 'INITIALIZE',
        payload: {
            accessToken: localStorage.getItem(storageKeys.AuthToken) ?? "",
            user: null,
            privileges: [],
            loading: false,
        }
    });
      setLoading(false)
    }
      
  };

    useEffect(() => {
        initialize();   
  }, [!isMobile]);

  const headerHandler = (title: any, backIcon: boolean, route: any) => {
    setHeaderTitle({title: title, backIcon: backIcon, route: route});
  }
 
  return (
    <AuthContext.Provider
      value={{
        ...state, headerHandler, headerTitle, errorMsg
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
