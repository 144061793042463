import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { Roles, Routes, storageKeys } from '../Static/constants';

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard = (props: AuthGuardProps) => {
    const { children } = props;
    const location = useLocation();
    const { user, loading } = useAuth();
    if ((location.pathname === '/' || location.pathname === '/authenticate') && !user && !localStorage.getItem(storageKeys.AuthToken)) {
        return <>{children}</>;
    } else if (location.pathname === '/error' && !user && localStorage.getItem(storageKeys.AuthToken)) {
        return <>{children}</>;
    }

    sessionStorage.setItem('campus',user?.campus?.length);
    
    return <>
        {
            !loading && !user &&
            localStorage.getItem(storageKeys.AuthToken) &&
            <Navigate to='/error' />
        }
        {
            [Roles.CRM_ADMIN,
            Roles.CRM_SUPER_ADMIN
            ].includes(user?.role?.name) &&
            <Navigate to={Routes.CRMDashboard} />
        }
        {
            [Roles.ENTERPRISE_ADMIN,
            Roles.ENTERPRISE_REPORT_ADMIN,
            Roles.ENTERPRISE_CAMPUS_ADMIN,
            Roles.ENTERPRISE_LEADER
            ].includes(user?.role?.name) && <Navigate to={Routes.Dashboard} />
        }
        {
            !user &&
            !localStorage.getItem(storageKeys.AuthToken) &&
            <Navigate to={Routes.Dashboard} />
        }
    </>;
};

export default AuthGuard;
