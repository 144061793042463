import axios from 'axios';
import { toast } from 'react-hot-toast';
import { apiToken, baseURL } from '../config';
import { storageKeys } from '../Static/constants';
import { keycloakLogout } from '../utils/common';

const addErrorAlert = (message: any) => {
  toast.error(message);
};

const authToken = () => localStorage.getItem(storageKeys.AuthToken);

const client = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${authToken()}`,
    'x-api-key': apiToken,
    Accept: 'application/json'
  },
});

client.interceptors.request.use((config: any) => {
  config.headers['Authorization'] = `Bearer ${authToken()}`;
  return config;
});

client.interceptors.request.use((config: any) => {
  return config;
});

client.interceptors.response.use((response: any) => {
  if (response) {
    const { headers } = response;
    let alert: string | null = null;
    Object.entries<string>(headers).forEach(([k, v]) => {
      if (k.toLowerCase().endsWith('app-alert')) {
        alert = v;
      }
    });
    if (alert) {
      toast.success(alert);
    }
  }
  return response;
},
  async (err: any) => {
    const { response } = err;
    const { data } = response;

    switch (err?.response?.status) {
      case 0:
        addErrorAlert('Server not reachable');
        break;
      case 400: {
        const headers = Object.entries<string>(response.headers);
        let errorHeader: string | null = null;
        let entityKey: string | null = null;
        headers.forEach(([k, v]: [string, string]) => {
          if (k.toLowerCase().endsWith('app-error')) {
            errorHeader = v;
          } else if (k.toLowerCase().endsWith('app-params')) {
            entityKey = v;
          }
        });
        if (errorHeader) {
          addErrorAlert(errorHeader);
        } else if (data !== '' && data.fieldErrors) {
          const { fieldErrors } = data;
          for (let i = 0; i < fieldErrors.length; i += 1) {
            const fieldError = fieldErrors[i];
            if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
              fieldError.message = 'Size';
            }
            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
            const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
            const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
            addErrorAlert(`Error on field "${fieldName}"`);
          }
        } else if (data !== '' && data.message) {
          addErrorAlert(data.message);
        } else {
          addErrorAlert(data);
        }
        break;
      }
      case 404:
        addErrorAlert(`Status ${err?.response?.status}: Not found`)
        break;
      case 401:
        if (data !== '' && data.message) {
          addErrorAlert(data.message);
          localStorage.removeItem(storageKeys.AuthToken);
          keycloakLogout();
        }
        break;
      case 403:
        if (data !== '' && data.message) {
          // localStorage.setItem(storageKeys.ErrorMessage, data.message);
        }
        break;
      default:
        if (data !== '' && data.message) {
          addErrorAlert(data.message);
        } else {
          addErrorAlert(data);
        }
    }

    return Promise.reject(err);
  });

export { client };
