const BASE_URL_APP = '';
const BASE_URL_API = '';

function appEndpoint(input: string) {
  return `${BASE_URL_APP}${input}`;
}

function apiEndpoint(
  input: string,
  searchValue?: string,
  replacementValue?: string
) {
  if (searchValue && replacementValue) {
    let endpoint = `${BASE_URL_API}${input}`;
    return endpoint.replace(searchValue, replacementValue);
  }

  return `${BASE_URL_API}${input}`;
}

const Endpoints = {
  login: appEndpoint('login'),
  enterprises: 'enterprise',
  profilePictureUrl: 'user',
  EnteriprizeReportsUrl: 'reports',
  batchRequestUrl: 'batchRequest',
  uploadAzuraUrl: 'upload-users',
  adminUsersUrl: 'user',
  rolesUrl: 'roles',
  enterpriseReportUrl: 'enterprise-reports',
  templateUrl: 'template/AzureADUsers.csv',
  keycloakTemplateUrl: 'template/KeyCloakUsers.csv',
  s3URL: 'document/s3url',
  CRMDashboard: 'crm-dashboard',
  EnterpriseDashboard: 'enterprise-dashboard',
  CampusUrl: '/campus',
  batchOptions: '/batchRequest/batch-params',
  EnterPriseSyncUrl: '/enterprise/sync',
  BatchRequestsSyncUrl: '/batchRequest/batch-status-sync',
  jobPack: '/jobpacks',
  reportType: 'reports/type',
  reportAccess: '/reports/report-access',
  editreportAccess: 'report-access',
  test: (language: string) => apiEndpoint('test?language=:language', ':language', language),
};

export default Endpoints;
