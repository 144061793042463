import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import './index.css';
import App from './App';
import { Toaster} from 'react-hot-toast';
import { msalConfig } from './utils/azureConfig';
import { AuthProvider } from './contexts/AuthContext';
import KeycloakServices from './KeycloakConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);
const renderApp = async () => {
  root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <AuthProvider>
              <>
                <Toaster position='top-right'/>
                <App />
              </>
            </AuthProvider>
          </BrowserRouter>
        </MsalProvider>
    </React.StrictMode>
  );
}

renderApp();

KeycloakServices.initKeycloak();