export const AzureConfig = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    tenantId: process.env.REACT_APP_TENANT_ID,
    redirectUrl: process.env.REACT_APP_REDIRECT_URL,
    redirectStartPage: process.env.REACT_APP_LOGIN_REDIRECT_START_PAGE
}


export const baseURL = process.env.REACT_APP_BE_BASE_URL;
export const apiToken = process.env.REACT_APP_BE_API_TOKEN;
export const wrapperURL = process.env.REACT_APP_RESTBASEURL;

export const keycloakConfig = {
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_KEYCLOAK_URL,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT
}

