import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { UnauthenticatedTemplate} from "@azure/msal-react";
import { storageKeys } from '../Static/constants';

interface RouteGuardProps {
    children: ReactNode;
}

const RouteGuard = (props: RouteGuardProps) => {
    const { children } = props;
    if (localStorage.getItem(storageKeys.AuthToken)) {
        return <>{children}</>;
    }

    return <>
        <UnauthenticatedTemplate>
            {
                <Navigate to="/" />
            }
        </UnauthenticatedTemplate>
    </>;
};

export default RouteGuard;
