import { Suspense, lazy } from 'react';
import DashboardLayout from '../Components/DashboardLayout';
import LoadingScreen from '../Components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import RouteGuard from '../guards/RouteGuard';
import { Privileges } from '../shared/enumerations/Privileges';
import { Routes } from '../Static/constants';

const Loadable = (Component: any) => (props: any) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Authentication pages
const SignIn = Loadable(lazy(() => import('../Pages/SignIn')));
const SplashScreen = Loadable(lazy(() => import('../Components/SplashScreen')));
const LogoutScreen = Loadable(lazy(() => import('../Pages/Logout')));
const ErrorScreen = Loadable(lazy(() => import('../Pages/Error')));

// Dashboard pages
const Dashboard = Loadable(lazy(() => import('../Pages/Dashboard')));
const Admins = Loadable(lazy(() => import('../Pages/SubAdmins')));
const Reports = Loadable(lazy(() => import('../Pages/Reports')));
const BatchDetails = Loadable(lazy(() => import('../Pages/BatchDetails')));
const LearnerPerformance = Loadable(lazy(() => import('../Pages/LearnerPerformance')));
const MasterData = Loadable(lazy(() => import('../Pages/MasterData')));
const CRMDashboard = Loadable(lazy(() => import('../Pages/CRMDashboard')));
const JobPack = Loadable(lazy(() => import('../Pages/JobPack')));
const ReportConfig = Loadable(lazy(() => import('../Pages/ReportConfig')));
const ExportReport = Loadable(lazy(() => import('../Pages/ExportReport')));
const ReportRequest = Loadable(lazy(() => import('../Pages/ReportRequest')));

const routes: any[] = [
    {
        path: '/',
        element: (
            <AuthGuard>
                <SignIn />
            </AuthGuard>
        )
    },
    {
        path: '/logout',
        element: (
            <LogoutScreen />
        )
    },
    {
        path: '/error',
        element: (
            <AuthGuard>
                <ErrorScreen />
            </AuthGuard>
        )
    },
    {
        path: '/authenticate',
        element: (
            <AuthGuard>
                <SplashScreen />
            </AuthGuard>
        )
    },    
    {
        path: '*',
        element: (
            <AuthGuard>
                <SplashScreen />
            </AuthGuard>
        )
    },
    {
        path: '/',
        element: (
            <RouteGuard>
                <DashboardLayout />
            </RouteGuard>
        ),
        children: [
            {
                path: Routes.Dashboard,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewEnterpriseDashboard, Privileges.ViewLeaderDashboard]}>
                        <Dashboard />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.Admins,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewEnterpriseAdmin]}>
                        <Admins />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.Reports,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewReport, Privileges.CreateReport, Privileges.UpdateReport, Privileges.DeleteReport, Privileges.ViewLeaderReports]}>
                        <Reports />
                    </RoleBasedGuard>
                )
            },
            {
                path: `${Routes.Reports}/:id`,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewReport, Privileges.CreateReport, Privileges.UpdateReport, Privileges.DeleteReport, Privileges.ViewLeaderReports]}>
                        <Reports />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.BatchRequests,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewBatchRequest, Privileges.CreateBatchRequest, Privileges.UpdateBatchRequest, Privileges.DeleteBatchRequest]}>
                        <BatchDetails />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.LearnerPerformance,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewLearnerPerformance]}>
                        <LearnerPerformance />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.MasterData,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewMasterData]}>
                        <MasterData />
                    </RoleBasedGuard>
                )
            },
            {
                path: `${Routes.MasterData}/enterprise-batches/:id`,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewMasterData]}>
                        <MasterData />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.CRMDashboard,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewCRMDashboard]}>
                        <CRMDashboard />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.ManageAdmins,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewCRMAdmin]}>
                        <Admins />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.BatchRequests,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewBatchRequest, Privileges.CreateBatchRequest, Privileges.UpdateBatchRequest, Privileges.DeleteBatchRequest]}>
                        <BatchDetails />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.JobPack,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewJobPack]}>
                        <JobPack />
                    </RoleBasedGuard>
                )
            },
            {
                path: `${Routes.JobPack}/:id`,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewJobPack]}>
                        <JobPack />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.ReportConfig,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewReportConfig]}>
                        <ReportConfig />
                    </RoleBasedGuard>
                )
            },
            {
                path: `${Routes.ReportConfig}/:id`,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewReportConfig]}>
                        <ReportConfig />
                    </RoleBasedGuard>
                )
            },
            {
                path: `${Routes.ReportConfig}/:id/:name`,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewReportConfig]}>
                        <ReportConfig />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.ExportReport,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewExportReport]}>
                        <ExportReport />
                    </RoleBasedGuard>
                )
            },
            {
                path: Routes.ReportRequest,
                element: (
                    <RoleBasedGuard RoutePrivileges={[Privileges.ViewReportRequest]}>
                        <ReportRequest />
                    </RoleBasedGuard>
                )
            }
        ]
    },
];

export default routes;
