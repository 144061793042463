import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import getS3Url from '../api/requests/getS3URL';

export const uploadFiletoS3 = (file: any, folder: string) => new Promise((resolve, reject) => {
    const instance = axios.create();
    instance.defaults.headers.common = {};
    const key = `${folder}/${uuidv4()}_${file?.name?.replaceAll(' ','')}`;
    const payLoad = {
        key
    };

    getS3Url(payLoad).then((res: any) => {
        instance
            .put(res.message, file, { headers: { 'Content-Type': file.type } })
            .then((result: any) => {
                result.key = payLoad.key;
                resolve(result);
            })
            .catch((error) => {
                console.log(error);
                reject(new Error('general.file_upload_failed'));
            });
    });
});
