import KeycloakServices from "../KeycloakConfig";
import { BatchStatus, ModuleNames, Routes, storageKeys } from "../Static/constants";

export const handleLogin = (lang?: string) => {
  };

export const handleLogout = () => {
 };

export const isAccessTokenExpired = (message: string) => {

  const messages = [
    'Access Token is Expired',
    'Invalid Authentication Token',
    'Signature verification failed',
    'Invalid Token Provided'
  ]
  return messages.includes(message);
}

export const isRefreshTokenExpired = (message: string) => {
  const messages = [
    'Token Expired'
  ]
  return messages.includes(message);
};


export const getModule = (userModule:string) =>{
  let currentModule = '';
  if (window.location.pathname === Routes.Admins) {
    currentModule = userModule;
  } else if (window.location.pathname === Routes.CRMAdmins) {
    currentModule = ModuleNames.CRM;
  } else if (window.location.pathname === Routes.EnterpriseAdmins) {
    currentModule = ModuleNames.ENTERPRISE;
  }
  return currentModule;
}

export const isRouteActive = (item: any) => window.location.pathname === item.path || `/${window.location.pathname.split('/')[1]}` === item.path;


export const getBatchStatusColor = (status: string) => {
  return status === BatchStatus.NEW
    ? '#614478'
    : status === BatchStatus.PENDING_AT_CRM
      ? '#008BAA'
      : status === BatchStatus.PENDING_AT_PLATFORM ? '#CEBEDA' : 'transparent';
}

export const getBatchStatusFontColor = (status: string) => {
  return status === BatchStatus.PENDING_AT_PLATFORM ? '#192434 !important' : 'white !important';
}

export const keycloakLogout = () => {
  localStorage.removeItem(storageKeys.AuthToken);
  localStorage.removeItem(storageKeys.SignInWith);
  localStorage.clear();
  KeycloakServices.logout();
}