import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { Privileges } from '../shared/enumerations/Privileges';
import { ModuleNames, Routes, storageKeys } from '../Static/constants';
import { hasAnyAuthority } from '../utils/hasAuthority';

interface RoleBasedGuardProps {
  children: ReactNode;
  RoutePrivileges?: string[];
}

const RoleBasedGuard = (props: RoleBasedGuardProps) => {
  const { children, RoutePrivileges } = props;
  const { privileges, loading, user } = useAuth();
  const location = useLocation();

  if (privileges?.length && RoutePrivileges && !hasAnyAuthority(privileges, RoutePrivileges)) {
    if (hasAnyAuthority(privileges, [Privileges.ViewEnterpriseDashboard, Privileges.ViewLeaderDashboard])) {
      return <Navigate to={Routes.Dashboard} />;
    } else {
      return <Navigate to={Routes.CRMDashboard} />;
    }
  } 
  // Restrict the  Delivery Manager and Lead to manually navigating to report page

  if (!loading && !user && localStorage.getItem(storageKeys.AuthToken)){  
    return <Navigate to='/error' />
  }

  return <>{children}</>;
};

export default RoleBasedGuard;
