export enum Privileges {
    CreateBatchRequest = 'CreateBatchRequest',
    ViewBatchRequest = 'ViewBatchRequest',
    UpdateBatchRequest = 'UpdateBatchRequest',
    DeleteBatchRequest = 'DeleteBatchRequest',
    CreateReport = 'CreateReport',
    ViewReport = 'ViewReport',
    UpdateReport = 'UpdateReport',
    DeleteReport = 'DeleteReport',
    CreateUser = 'CreateUser',
    ViewUser = 'ViewUser',
    UpdateUser = 'UpdateUser',
    DeleteUser = 'DeleteUser',
    CreateEnterprise = 'CreateEnterprise',
    ViewEnterprise = 'ViewEnterprise',
    UpdateEnterprise = 'UpdateEnterprise',
    DeleteEnterprise = 'DeleteEnterprise',
    ViewEnterpriseDashboard = 'ViewEnterpriseDashboard',
    ViewCRMDashboard = 'ViewCRMDashboard',
    ViewMasterData = 'ViewMasterData',
    ViewEnterpriseAdmin = 'ViewEnterpriseAdmin',
    ViewCRMAdmin = 'ViewCRMAdmin',
    ViewLearnerPerformance = 'ViewLearnerPerformance',
    UploadUsersToFederation = 'UploadUsersToFederation',
    ViewJobPack = 'ViewJobPack',
    ViewReportConfig = 'ViewReportConfig',
    ViewExportReport = 'ViewExportReport',
    ViewReportRequest = 'ViewReportRequest',
    ViewLeaderDashboard = 'ViewLeaderDashboard',
    ViewLeaderReports = 'ViewLeaderReports'
}