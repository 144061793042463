import Keycloak from 'keycloak-js';
import { keycloakConfig } from './config';
import { federations, storageKeys } from './Static/constants';

  
const keycloak: any = new Keycloak(keycloakConfig as any);
  
const initKeycloak = async () => {
    keycloak.init({
        onLoad: "login-required",
        // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        // pkceMethod: 'S256',
        checkLoginIframe: false,
        flow:'implicit',
    })
    .then((authenticated: boolean) => {
        if( authenticated ) {
            // localStorage.setItem(storageKeys.SignInWith, federations.KEYCLOAK);
            localStorage.setItem(storageKeys.AuthToken, keycloak?.token);
        }
    });
};

const login = keycloak.login;

const logout = keycloak.logout;

const getToken = () => keycloak?.token;

const KeycloakServices = {
    initKeycloak,
    login,
    logout,
    getToken,
};

export default KeycloakServices;
